<template>
  <div class="container">
    <el-breadcrumb separator-class="el-icon-arrow-right" style="	height: 40px;">
      <el-breadcrumb-item>{{
        $store.state.language === 1 ? '首页' : 'Main Page'
      }}
      </el-breadcrumb-item>
      <!-- <el-breadcrumb-item></el-breadcrumb-item> -->
    </el-breadcrumb>
    <div class="top">
      <el-input :placeholder="fun.getLanguageText($store.state.language, '请输入关键词进行搜索')"
        v-model="searchData.value"></el-input>
      <el-button type="primary" icon="el-icon-search" @click="search">{{
        fun.getLanguageText($store.state.language, "搜索")
      }}
      </el-button>
      <el-select v-model="searchData.type1" :placeholder="fun.getLanguageText($store.state.language, '请选择设备类型')"
        @change="changeSearchData($event, 1)">
        <el-option :label="item.name" :value="item.id" v-for="item in DeviceTypeList1" :key="item.id">
        </el-option>
      </el-select>
      <el-select v-model="searchData.type2" :placeholder="fun.getLanguageText($store.state.language, '请选择设备名称')"
        @change="changeSearchData($event, 2)">
        <el-option :label="item.name" :value="item.id" v-for="item in DeviceTypeList2" :key="item.id">
        </el-option>
      </el-select>
      <el-select v-model="searchData.type3" :placeholder="fun.getLanguageText($store.state.language, '请选择设备型号')"
        @change="changeSearchData($event, 3)">
        <el-option :label="item.name" :value="item.id" v-for="item in DeviceTypeList3" :key="item.id">
        </el-option>
      </el-select>
      <el-select v-model="searchData.type4" :placeholder="fun.getLanguageText($store.state.language, '请选择设备状态')" disabled>
        <el-option :label="fun.getLanguageText($store.state.language, '正常')" value="1"></el-option>
        <el-option :label="fun.getLanguageText($store.state.language, '关机')" value="2"></el-option>
        <el-option :label="fun.getLanguageText($store.state.language, '无信号')" value="3"></el-option>
      </el-select>
    </div>
    <div class="main" :style="'height:' + $store.state.clientHeight + 'px;'">
      <div class="item" v-for="item in tableData" :key="item.id" @click="toDevice(item)">
        <div class="item-item" style="width:50%;">
          <div class="item-key">{{
            $store.state.language === 1 ? '客户名称' : 'Customer Name'
          }}
          </div>
          <div class="item-value">{{ item.customer }}</div>
        </div>
        <div class="item-item" style="width:50%;">
          <div class="item-key">{{
            $store.state.language === 1 ? '终端客户名称' : 'End User Name'
          }}
          </div>
          <div class="item-value">{{ item.client }}</div>
        </div>
        <div class="item-item" style="width:50%;">
          <div class="item-key">{{
            $store.state.language === 1 ? '设备种类' : 'Device Type'
          }}
          </div>
          <div class="item-value">{{ item.deviceType }}</div>
        </div>
        <div class="item-item" style="width:50%;">
          <div class="item-key">{{
            $store.state.language === 1 ? '设备名称' : 'Device Name'
          }}
          </div>
          <div class="item-value">{{ item.deviceName }}</div>
        </div>
        <div class="item-item" style="width:50%;">
          <div class="item-key">{{
            $store.state.language === 1 ? '设备型号' : 'Model Number'
          }}
          </div>
          <div class="item-value">{{ item.deviceModel }}</div>
        </div>
        <div class="item-item" style="width:50%;">
          <div class="item-key">{{
            $store.state.language === 1 ? '设备序列号' : 'Serial Number'
          }}
          </div>
          <div class="item-value">{{ item.sn }}</div>
        </div>
        <div class="item-item" style="width:50%;">
          <div class="item-key">{{
            $store.state.language === 1 ? '设备状态' : 'Device Status'
          }}
          </div>
          <div class="item-value tips" :class="{ red: item.active === '离线', green: item.active === '在线' }">{{
            fun.getLanguageText($store.state.language, item.active)
          }}
          </div>
        </div>
        <div class="item-item" style="width:50%;">
          <div class="item-key">{{
            $store.state.language === 1 ? '设备持续工作时间' : 'Working Duration'
          }}
          </div>
          <div class="item-value">{{ item.startup_time == 0 ? 'N/A' : getEtime(item.startup_time) }}</div>
        </div>
        <div class="item-item" style="width:100%;">
          <div class="item-key">{{
            $store.state.language === 1 ? '位置' : 'Location'
          }}
          </div>
          <div class="item-value">{{ $store.state.language === 1 ? item.place : item.place_en }}</div>
        </div>
      </div>
    </div>
    <el-pagination class="footer" background layout="sizes, prev, pager, next, jumper, total"
      :page-size="pageData.pageSize" :current-page="pageData.currentPage" :total="pageData.total"
      :page-sizes="pageData.pageSizes" @size-change="sizeChange" @current-change="currentChange">
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      router: false,
      input: '',
      user_type: '',
      tableData: [],
      searchData: {
        value: '', // 查询内容
        type1: '', // 类型ID
        type2: '', // 名称id
        type3: '', // 型号id
        type4: ''
      },
      DeviceTypeList1: [],
      DeviceType1: 0,
      DeviceTypeList2: [],
      DeviceType2: 0,
      DeviceTypeList3: [],
      DeviceType3: 0,
      hA: 0,
      pageData: {
        total: 0, // 总条数
        currentPage: 1, // 当前页数
        totalPage: 0, // 总页数
        pageSize: 20, //
        pageSizes: [10, 20, 30, 50]
      }
    }
  },
  methods: {
    // 获取英文文本
    getEtime(str) {
      if (this.$store.state.language == 2) {
        let s = str
        s = s.replace(/天/, "D")
        s = s.replace(/小时/, "H")
        s = s.replace(/分钟/, "M")
        return s
      }
      return str
    },
    // 进入设备详情
    toDevice(e) {
      // this.$router.push('')
    },
    // 搜素
    search() {
      this.get_device_info()
    },
    // 获取主页列表
    async get_device_info() {
      let res = await this.$http.post('device/device-info', {
        search: this.searchData.value,
        deviceTypeId: this.searchData.type1,
        deviceNameId: this.searchData.type2,
        deviceModelId: this.searchData.type3,
        currentPage: this.pageData.currentPage,
        pageSize: this.pageData.pageSize
      })
      if (res.data.code === 2000) {
        this.tableData = res.data.data.info
        this.pageData.total = Number(res.data.data.page.total)
        return
      }
    },
    // 下拉选择框被改变
    changeSearchData(e, id) {
      console.log(e, id)
      if (id === 1) {
        this.DeviceType1 = e
        this.searchData.type2 = ''
        this.searchData.type3 = ''
        this.DeviceTypeList2 = []
        this.DeviceTypeList3 = []
        this.getDeviceTypeList(2, e)
      }
      if (id === 2) {
        this.DeviceType2 = e
        this.searchData.type3 = ''
        this.DeviceTypeList3 = []
        this.getDeviceTypeList(3, e)
      }
      if (id === 3) {
        this.DeviceType3 = e
      }
      console.log(this.searchData)
      this.get_device_info()
    },
    // 获取设备类型列表
    getDeviceTypeList(type, pid) { // 1:类型 2:名称 3型号
      this.$http.post('device-type/list', {
        type,
        pid
      }).then(res => {
        if (res.data.code === 2000) {
          if (type == 1) {
            this.DeviceTypeList1 = res.data.data
          }
          if (type == 2) {
            this.DeviceTypeList2 = res.data.data
          }
          if (type == 3) {
            this.DeviceTypeList3 = res.data.data
          }
        }
      })
    },
    // 改变页码
    currentChange(e) {
      this.pageData.currentPage = e
      this.get_device_info()
    },
    // 选择页码
    sizeChange(e) {
      this.pageData.pageSize = e
      this.get_device_info()
    },
    // 计算高度
    getListHeight() {
      let Html = document.getElementsByClassName('box-card')
      if (Html.length > 0) {
        this.$store.state.clientHeight = Html[0].clientHeight - 131 - 60
      }
    }
  },
  created() {
    this.getDeviceTypeList(1)
    this.get_device_info()
  },
  mounted() {
    this.getListHeight()
    window.onresize = () => {
      return (() => {
        this.getListHeight()
      })();
    }
  },
  destroyed() {
    window.onresize = null;
  }
};
</script>

<style lang="less" scoped>
.el-input {
  width: 240px;
  margin-right: 10px;
}

.el-select {
  width: 180px;
  margin-left: 20px;
}

.container {
  display: flex;
  flex-direction: column;
}

.top {
  // height: 32px;
  margin-bottom: 20px;
}

.main {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // flex: 1;
  // justify-content: space-between;
}


.item {
  width: 400px;
  height: 326px;
  background-color: #F5F5F5;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.07);
  border: 1px solid rgba(187, 187, 187, 100);
  margin-right: 1px;
}

.item-item {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;

  .item-key {
    font-size: 10px;
    color: #888888;
    margin-bottom: 4px;
  }

  .item-value {
    font-size: 14px;
    color: #101010;
  }
}

.footer {
  display: flex;
  padding: 0;
  margin-top: 14px;
  justify-content: flex-end;
}

.tips {
  display: flex;
  align-items: center;
}


.red:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: red;
  margin-right: 8px;
  border-radius: 100%;
}

.green:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: green;
  margin-right: 8px;
  border-radius: 100%;
}
</style>
